import React, { Fragment } from 'react';
import ContactForm from '../../forms/Contact';
import * as styles from './Contact.module.scss';


const Contact = () => (
  <Fragment>
    <iframe
      title="google map"
      marginHeight="0"
      marginWidth="0"
      scrolling="no"
      src="https://maps.google.co.uk/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=11+Trevena+Terrace,+Newquay&amp;aq=0&amp;oq=11+trevena&amp;sll=52.8382,-2.327815&amp;sspn=12.478038,19.313965&amp;t=h&amp;ie=UTF8&amp;hq=&amp;hnear=11+Trevena+Terrace,+Newquay+TR7+1LJ,+United+Kingdom&amp;z=15&amp;iwloc=A&amp;output=embed"
      frameBorder="0"
      height="350"
      width="100%"
    />
    <br />
    <br />
    <div className={styles.content}>
      <div className={styles.address}>
        <h3>Address</h3>
        <div>Trevena House, 11 Trevena Terrace, Newquay, Cornwall, United Kingdom. TR7 1LJ</div>
        <br />
        {/* <div>Telephone: <a href="tel:+441637 873714">+44(0)1637 873714</a></div>
        <br /> */}
        <div>E-mail: <a href="mailto:contact@flowmo.co">contact@flowmo.co</a></div>
      </div>
      <div className={styles.contactForm}>
        <ContactForm />
      </div>
    </div>
  </Fragment>
);

export default Contact;
