import React from 'react';
import PropTypes from 'prop-types';
import { overScrollColors } from '../../util/scrollTrack';
import useSiteMetaHook from '../../hooks/useSiteMetaHook';
import '../../util/fontAwesome';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import * as styles from './Layout.module.scss';

const Layout = ({ pageTitle, children }) => {
  const { title } = useSiteMetaHook();
  overScrollColors('#60AF9A', '#353535');
  return (
    <div className={styles.container}>
      <Header className={styles.header} title={title} />
      <div className={styles.content}>
        <main>
          {pageTitle && <h1 className={styles.pageTitle}>{pageTitle}</h1>}
          {children}
        </main>
      </div>
      <Footer className={styles.footer} waveBackgroundColor="white" />
    </div>
  );
};

Layout.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  pageTitle: null,
};

export default Layout;
