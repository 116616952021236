import React from 'react';
import Layout from '../components/Layout/Layout';
import Contact from '../templates/Contact/Contact';
import SEO from '../components/seo';

const ContactPage = () => (
  <Layout pageTitle="Contact">
    <SEO title="Contact" keywords={['contact']} />
    <Contact />
  </Layout>
);

export default ContactPage;
