// extracted by mini-css-extract-plugin
export var app = "Layout-module--app--2FTSV";
export var container = "Layout-module--container--AI465";
export var content = "Layout-module--content--QwAXS";
export var footer = "Layout-module--footer--zec99";
export var h1 = "Layout-module--h1--u0Uiv";
export var h2 = "Layout-module--h2--gTft7";
export var h3 = "Layout-module--h3--cVj1b";
export var h4 = "Layout-module--h4--PYZeG";
export var h5 = "Layout-module--h5--BeZ8i";
export var h6 = "Layout-module--h6--pm8rD";
export var header = "Layout-module--header--fYiCf";
export var p = "Layout-module--p--pVRij";
export var pageTitle = "Layout-module--pageTitle--el45e";